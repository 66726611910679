import React, { useState, useEffect } from "react";
import mobileamr from "./image/mob1.png";
function HeroSectionMobile() {
  const [, setTimer] = useState(timestampToDHMS());

  function timestampToDHMS() {
    var date = new Date("28 Jun 2023 12:00");
    var timestamp = date.getTime();
    const timeLeft = timestamp - Date.now();
    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        const updatedTimer = { ...prevTimer };
        if (updatedTimer.seconds === 0) {
          if (updatedTimer.minutes === 0) {
            if (updatedTimer.hours === 0) {
              if (updatedTimer.days === 0) {
                clearInterval(interval); // Timer reached 0, stop the interval
              } else {
                updatedTimer.days--;
                updatedTimer.hours = 23;
                updatedTimer.minutes = 59;
                updatedTimer.seconds = 59;
              }
            } else {
              updatedTimer.hours--;
              updatedTimer.minutes = 59;
              updatedTimer.seconds = 59;
            }
          } else {
            updatedTimer.minutes--;
            updatedTimer.seconds = 59;
          }
        } else {
          updatedTimer.seconds--;
        }
        return updatedTimer;
      });
    }, 1000);

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);

  return (
    <div className=" center_hero_logo">
      <br />
      <br />
      <h1 className="mobile_hero_text">
        <i>Unleash</i> your <br />
        <span className="mobile_hero_text_art">Art Gallery's</span> <br />
        full potential
      </h1>

      <br />
      <p className="hero_logo_under_text_mobile">
        Join now and start selling to a global audience on <br />
        the{" "}
        <span style={{ color: "#fff", fontWeight: "800", fontSize: "14px" }}>
          most innovative art platform in the world
        </span>
      </p>
      <br />

      <a href="early-access" className="myButton_head_Mobile">
        Request Access
      </a>
      <br />
      <br />
      <p className="hero_logo_under_text_mobile">
        Galleries from{" "}
        <span style={{ color: "#ffb500", fontWeight: "800" }}>
          42 countries
        </span>{" "}
        have already joined Suarte!
      </p>

      <img src={mobileamr} className="Mobile_amr" alt="mobile_amr" />

      {/* <div className="hero_time_lines ">
        
        
        <div className="container">
          <Grid container spacing={1}>
            <Grid item md={4} lg={4} sm={6} xs={6} style={{display: "flex", alignItems: "center"}}>
              <img src={com} className="Desktop_logo_button_joint" />
            </Grid>
            <Grid item md={4} lg={4} sm={6} xs={6}>
            <a href="early-access" className="myButton_head_Desktop_Joint">
            Join now
          </a> 
              <h2 className="timers">
                {timer.days < 10 ? `0${timer.days}` : timer.days}{" "}
                <span style={{ color: "#fff" }}>:</span>{" "}
                {timer.hours < 10 ? `0${timer.hours}` : timer.hours}{" "}
                <span style={{ color: "#fff" }}>:</span>{" "}
                {timer.minutes < 10 ? `0${timer.minutes}` : timer.minutes}{" "}
                <span style={{ color: "#fff" }}>:</span>{" "}
                {timer.seconds < 10 ? `0${timer.seconds}` : timer.seconds}
              </h2>
             
            </Grid>
            <Grid item md={4} lg={4} sm={6} xs={6}>
              
            </Grid>
          </Grid>
        </div>
      </div> */}
    </div>
  );
}

export default HeroSectionMobile;
