import React from "react";
import { useMediaQuery } from "@mui/material";

import SuartaSlider from "../Component/SuartaSliderMain/SuartaSliderMain";
import MobileSuartaSlider from "../Component/MobileSuartaSliderMain/MobileSuartaSliderMain";

const LandingPage = () => {
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  return (
    <div>
      {" "}
      {!smallScreen && (
        <div className="mainContent">
          <div className="pageCon">
            <SuartaSlider />
          </div>
        </div>
      )}
      {/* This is used For the mobile view Blue print Map*/}
      {smallScreen && (
        <div className="mainContentmob">
          <div className="pageConmob">
            <MobileSuartaSlider />
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
