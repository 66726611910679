import React from "react";

import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-react-ui-kit";

import { Grid } from "@mui/material";

import free from "./image/free.png";
import social from "./image/global.png";
import globals from "./image/social.png";

function UnderHeroCards() {
  return (
    <div className="container">
      <Grid container spacing={2}>
        <Grid item md={4} lg={4} xs={12} sm={12}>
          <MDBCard className="backgroundCard">
            <MDBCardBody>
              <MDBCardTitle className="card_head_text">
                <img
                  src={free}
                  className="icons_need_small"
                  alt="under-hero-card-1"
                />
              </MDBCardTitle>
              <MDBCardTitle className="card_head_text">
                Free for all
              </MDBCardTitle>
              <MDBCardText className="card_under_text">
                Suarte provides a{" "}
                <span style={{ fontWeight: "700" }}>zero subscription </span>fee
                sales platform for{" "}
                <span style={{ fontWeight: "700", color: " #FFB500" }}>
                  Art Galleries
                </span>{" "}
                while also offering a social, free-to-use app for{" "}
                <span style={{ fontWeight: "700", color: " #FFB500" }}>
                  art lovers
                </span>
                ,{" "}
                <span style={{ fontWeight: "700", color: " #FFB500" }}>
                  represented artists
                </span>
                , and{" "}
                <span style={{ fontWeight: "700", color: " #FFB500" }}>
                  collectors
                </span>
                .
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </Grid>

        <Grid item md={4} lg={4} xs={12} sm={12}>
          <MDBCard className="backgroundCard">
            <MDBCardBody>
              <MDBCardTitle className="card_head_text">
                <img
                  src={social}
                  className="icons_need_small"
                  alt="under-hero-card-2"
                />
              </MDBCardTitle>
              <MDBCardTitle className="card_head_text">
                Social features
              </MDBCardTitle>
              <MDBCardText className="card_under_text">
                Introducing
                <span style={{ fontWeight: "700" }}> groundbreaking</span>{" "}
                social features to engage and bring the art world closer than
                ever before to its users.{" "}
                <span style={{ fontWeight: "700", color: " #FFB500" }}>
                  Likes
                </span>
                ,{" "}
                <span style={{ fontWeight: "700", color: " #FFB500" }}>
                  ratings
                </span>
                ,{" "}
                <span style={{ fontWeight: "700", color: " #FFB500" }}>
                  thoughts
                </span>{" "}
                and more!
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </Grid>
        <Grid item md={4} lg={4} xs={12} sm={12}>
          <MDBCard
            className="backgroundCard"
            style={{ position: "relative", top: "1px" }}
          >
            <MDBCardBody>
              <MDBCardTitle className="card_head_text">
                <img
                  src={globals}
                  className="icons_need_small"
                  alt="under-hero-card-3"
                />
              </MDBCardTitle>
              <MDBCardTitle className="card_head_text">
                Global exposure
              </MDBCardTitle>
              <MDBCardText className="card_under_text">
                Gain immediate{" "}
                <span style={{ fontWeight: "700" }}>worldwide visibility </span>
                for your gallery and artworks, reaching a diverse{" "}
                <span style={{ fontWeight: "700", color: " #FFB500" }}>
                  audience
                </span>{" "}
                of art lovers and collectors.
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </Grid>
      </Grid>
    </div>
  );
}

export default UnderHeroCards;
