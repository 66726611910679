import React, { useState } from "react";
import { Grid } from "@mui/material";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardHeader from "@mui/material/CardHeader";

import pablolink from "./image/mpablink.png";

import marianalink from "./image/marinalink.png";

import javilink from "./image/javm.png";

import franlink from "./image/franlink.png";

import contact from "./image/contactform.png";
import book from "./image/book.png";

import sho from "./image/shoe.png";
import fetchWrapper from "../../fetchWrapper";

import { AiFillLinkedin } from "react-icons/ai";
import API_URL from "../../config/api-config";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

function DesktopAbout() {
  const [open, setOpen] = React.useState(false);
  const [contactState, setContactState] = useState({ email: "", message: "" });
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  async function submitContact() {
    if (loading) return;
    if (!contactState.email || !contactState.message) {
      alert("All fields are required!");
      return;
    }

    setLoading(true);

    const { response } = await fetchWrapper.post(`${API_URL}/contact`, {
      body: {
        contact_name: "Early access",
        contact_email: contactState.email,
        contact_message: contactState.message,
      },
    });

    if (response.ok) {
      alert("Message sent successfully!");
      setContactState({ email: "", message: "" });
      setLoading(false);
      handleClose();
    } else {
      alert("Something went wrong. The email provided might be invalid.");
      setContactState({ email: "", message: "" });
      setLoading(false);
    }
  }

  return (
    <div className="">
      <br />
      <Grid container spacing={1}>
        <Grid item sm={12} xs={12}>
          <h1 className="head_about">ABOUT US</h1>

          <div className="container">
            <h6 className="heading_about_top">Our mission</h6>
            <p className="under_about_top">
              At Suarte, we are driven by one clear goal:
              <span style={{ fontWeight: "900" }}> to make art mainstream</span>
              . Art, in its purest form, has the power to inspire, provoke
              thought, and transform lives. But for this to happen, it needs to
              be accessible, relatable, and a part of everyday life.
            </p>

            <h6 className="heading_about_top">Our belief</h6>
            <p className="under_about_top">
              Galleries, as gatekeepers of the art world, hold the key to making
              this possible. We believe that empowering the bridge between
              Artists and art lovers can reveal the true potential of the art
              market.
            </p>

            <h6 className="heading_about_top">Our team</h6>
            <p className="under_about_top">
              Suarte is the brainchild of six outsiders with a shared vision and
              values. It has been a journey of heart, creativity and dedication,
              and we could not be happier to share with you that Suarte has
              arrived!
            </p>
            <br />

            <br />
          </div>
        </Grid>
      </Grid>
      <div className="container">
        <Grid container spacing={2}>
          {[
            {
              name: "Pablo",
              role: "CEO and Founder",
              image:
                "https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/901b869b-7167-40cf-5a98-03f544510d00/w=1200",
              link: pablolink,
              href: "https://www.linkedin.com/in/pablo-gil-mart%C3%ADnez-07a602170/",
              linkedInName: "Pablo Gil",
            },
            {
              name: "Marina",
              role: "Design Director and Co-Founder",
              image:
                "https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/e9e27163-6925-4d39-0fb5-7f6499cfc900/w=1200",
              link: marianalink,
              href: "https://www.linkedin.com/in/marina-s%C3%A1nchez-02b725259/",
              linkedInName: "Marina Sánchez",
            },
            {
              name: "Javi",
              role: "Marketing Director",
              image:
                "https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/329247ac-3b3e-405a-d937-a7007c078200/w=1200",
              link: javilink,
              href: "https://www.linkedin.com/in/javier-rizo-cerd%C3%A1n-184b35108/",
              linkedInName: "Javier Rizo",
            },
            {
              name: "Francisco",
              role: "Gallery Partnership Director",
              image:
                "https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/9ae5e80d-6017-4ee2-c259-9b04569e8100/w=1200",
              link: franlink,
              href: "https://www.linkedin.com/in/francisco-landivar-389b831b6/",
              linkedInName: "Francisco Landivar",
            },
          ].map((member, index) => (
            <Grid item key={index} md={6} lg={6} xs={6} sm={6}>
              <Card
                sx={{ display: "flex", width: "100%" }}
                className="card_bg_team"
              >
                <CardMedia
                  component="img"
                  style={{
                    width: "60px",
                    height: "60px",
                    position: "relative",
                    top: "10px",
                    objectPosition: "center",
                    borderRadius: "50%",
                  }}
                  image={member.image}
                  alt={`${member.name} - Live from space album cover`}
                />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flex: "1 0 auto", paddingRight: "0px" }}>
                    <h3 className="head_team_card">{member.name}</h3>
                    <p className="under_team_card">{member.role}</p>
                    {member.link && (
                      <a
                        style={{ textDecoration: "none" }}
                        href={member.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p
                          style={{
                            color: "white",
                            fontSize: "8px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <AiFillLinkedin
                            style={{
                              width: "8px",
                              height: "auto",
                              color: "#FA0194",
                              marginRight: "3px",
                              flexShrink: 0,
                            }}
                          />

                          {member.linkedInName}
                        </p>
                      </a>
                    )}
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
      <Grid container spacing={1}>
        <Grid item sm={6} xs={6}>
          <div className="shoe_hover_mobile">
            <img
              src={sho}
              style={{ width: "13%" }}
              className="vert-move"
              alt="vert-move"
            />
          </div>
          {[
            {
              name: "Contact Form",
              final:
                "Fill out the contact form if you have any questions, feedback, or simply want to say hello.",
              image: contact,
              link: pablolink,
            },
            {
              name: "Book a meeting ",
              final:
                "Need personalized assistance? Don't hesitate to reach out and schedule a meeting.",
              image: book,
              link: marianalink,
              href: "",
            },
          ].map((member, index) => (
            <div
              onClick={
                member.name === "Contact Form"
                  ? handleOpen
                  : () =>
                      (window.location = "https://zcal.co/suarte/gallery")
              }
              className="container"
              item
              key={index}
              style={{ margin: "10px", cursor: "pointer" }}
            >
              <Card sx={{ maxWidth: 345 }} className="last_card_about_bg">
                <CardHeader
                  avatar={
                    <CardMedia
                      component="img"
                      style={{ width: "20px" }}
                      image={member.image}
                      alt={`${member.name} - Live from space album cover`}
                    />
                  }
                  title={<h1 className="last_card_head">{member.name}</h1>}
                />

                <CardContent
                  style={{ paddingTop: "0px", paddingBottom: "0px" }}
                >
                  <p className="last_card_para">{member.final}</p>
                </CardContent>
              </Card>
            </div>
          ))}
        </Grid>
        <Grid item sm={6} xs={6}>
          <img
            src="https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/12735fbc-f1f7-42f0-62c4-296afeb91400/w=1000"
            style={{ width: "100%" }}
            alt="mobile-about-2"
          />
        </Grid>
      </Grid>

      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal_bg"
        >
          <Box sx={style} className="modals_bg">
            <h1 className="head_of_slidetwom">
              {" "}
              <span style={{ color: "#FFF" }}> Contact us </span>
            </h1>

            <Grid
              item
              md={11}
              lg={12}
              xs={12}
              sm={12}
              style={{
                textAlign: "center",
                position: "relative",
              }}
            >
              <input
                type="email"
                placeholder="Email"
                label="fullWidth"
                className="css-input_bussinescc"
                value={contactState.email}
                onChange={(e) =>
                  setContactState({ ...contactState, email: e.target.value })
                }
              />
              <br /> <br />
              <textarea
                placeholder="Message"
                label="Message"
                className="css-input_bussinescc"
                value={contactState.message}
                onChange={(e) =>
                  setContactState({ ...contactState, message: e.target.value })
                }
              ></textarea>
              <br />
              <br />
              <button
                className="myButton_head_Desktop_Choose_pink_zancgg"
                onClick={submitContact}
              >
                {loading ? "Loading..." : "Send"}
              </button>
            </Grid>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default DesktopAbout;
