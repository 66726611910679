import React from "react";

import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-react-ui-kit";

import { Grid } from "@mui/material";

import free from "./image/target.png";
import social from "./image/expe.png";
import globals from "./image/ease.png";

function UnderHeroCards() {
  return (
    <div className="container ">
      <Grid container spacing={2}>
        <Grid item md={4} lg={4} xs={12} sm={12}>
          <MDBCard className="backgroundCard">
            <MDBCardBody>
              <MDBCardTitle className="card_head_text">
                <img
                  src={free}
                  className="icons_need_small"
                  alt="under-hero-cards"
                />
              </MDBCardTitle>
              <MDBCardTitle className="card_head_text_target">
                Targeted reach
              </MDBCardTitle>
              <MDBCardText className="card_under_text">
                Find the{" "}
                <span style={{ fontWeight: "700" }}>ideal collector</span>{" "}
                through{" "}
                <span style={{ fontWeight: "700", color: " #FF7900" }}>
                  algorithms{" "}
                </span>
                that are tailored to individual interests and preferences.
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </Grid>

        <Grid item md={4} lg={4} xs={12} sm={12}>
          <MDBCard className="backgroundCard ">
            <MDBCardBody>
              <MDBCardTitle className="card_head_text">
                <img
                  src={social}
                  className="icons_need_small"
                  alt="under-hero-card-2"
                />
              </MDBCardTitle>
              <MDBCardTitle className="card_head_text_target">
                Maximize profits
              </MDBCardTitle>
              <MDBCardText className="card_under_text">
                Take advantage of{" "}
                <span style={{ fontWeight: "700" }}>
                  Suarte’s Limited Editions, referral strategies
                </span>
                , and <span style={{ fontWeight: "700" }}>analytics</span> to
                optimize your Gallery's{" "}
                <span style={{ fontWeight: "700", color: " #FF7900" }}>
                  growth and profitability.
                </span>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </Grid>

        <Grid item md={4} lg={4} xs={12} sm={12}>
          <MDBCard
            className="backgroundCard"
            style={{ position: "relative", top: "2px" }}
          >
            <MDBCardBody>
              <MDBCardTitle className="card_head_text">
                <img
                  src={globals}
                  className="icons_need_small"
                  alt="under-hero-card-3"
                />
              </MDBCardTitle>
              <MDBCardTitle className="card_head_text_target">
                Ease of use
              </MDBCardTitle>
              <MDBCardText className="card_under_text">
                <span style={{ fontWeight: "700" }}>Intuitive</span> and
                user-friendly interface makes it{" "}
                <span style={{ fontWeight: "700", color: " #FF7900" }}>
                  easier than ever
                </span>{" "}
                to <span style={{ fontWeight: "700" }}>manage</span>,{" "}
                <span style={{ fontWeight: "700" }}>sell</span> and{" "}
                <span style={{ fontWeight: "700" }}>collect</span> artworks.
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </Grid>
      </Grid>
    </div>
  );
}

export default UnderHeroCards;
