import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme";
import "./App.css";
import LandingPage from "./Pages/LandingPage";
import ForoFor from "./Pages/404";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SuartaMainSlider from "./Pages/SliderPage";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <>
          <Router>
            <Routes>
              <Route path="/" exact={true} element={<LandingPage />}/>

              <Route
                path="/early-access"
                exact={true}
                element={<SuartaMainSlider />}
              />
              
              <Route path="*" exact={true} element={<ForoFor />} />
            </Routes>
          </Router>
        </>
      </div>
    </ThemeProvider>
  );
};

export default App;
