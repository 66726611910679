import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { EffectFade, Navigation } from "swiper";

export default function App({ setCurrentSlide }) {
  return (
    <>
      <Swiper
        effect={"fade"}
        grabCursor={true}
        navigation={true}
        modules={[EffectFade, Navigation]}
        className="mySwiper swipers"
        onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
      >
        <SwiperSlide className="swiper-slides">
          <img
            src="https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/e581f58c-6aaf-4880-175b-7a35823b1200/w=800"
            alt="swiper_image_1"
          />
        </SwiperSlide>

        <SwiperSlide className="swiper-slides">
          <img
            src="https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/c0ed3caa-b3ad-44bc-2bba-cde76d8e3600/w=800"
            alt="swiper_image_2"
          />
        </SwiperSlide>

        <SwiperSlide className="swiper-slides">
          <img
            src="https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/1e849965-ade9-45fc-e0a4-f543329c5a00/w=800"
            alt="swiper_image_3"
          />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
