import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "./image/logo.png";

function NavbarMobile({ navigateToEssentials }) {
  const linkStyles = {
    color: "#FFB500",
    fontWeight: "700",
    cursor: "pointer",
    marginRight: "20px",
    textDecoration: "none",
    transition: "color 0.3s ease",
  };

  const hoverStyles = {
    color: "#FFD700",
  };

  const handleMouseEnter = (e) => {
    e.target.style.color = hoverStyles.color;
  };

  const handleMouseLeave = (e) => {
    e.target.style.color = linkStyles.color;
  };

  return (
    <div>
      <Navbar>
        <Container>
          <Navbar.Brand>
            {" "}
            <img src={logo} className="head_logo" alt="navbar-mobile" />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text
              style={{
                ...linkStyles,
              }}
              onClick={navigateToEssentials}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              FAQ
            </Navbar.Text>
            <a
              href="https://suarte.art"
              style={{
                ...linkStyles,
              }}
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Visit Suarte
            </a>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavbarMobile;
